import React, { Component } from "react";
import { Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import {
  ArrowForwardIos as ArrowForward,
  Schedule as MoveDurationIcon,
  LocalParking as StopDurationIcon,
  DirectionsBus as KmsIcon,
} from "@material-ui/icons";

import FleetChip from "components/ui/chips/FleetChip";
import FleetSelect from "components/ui/select/FleetSelect";
import Btn from "components/ui/button/Btn";
import AutoComplete from "components/ui/autocomplete/AutoComplete";
import UiDatePicker from "components/ui/uidatepicker/UiDatePicker";
import TrackTimeline from "./tracktimeline/TrackTimeline";
import KmTravelled from "./KmTravelled";

import {
  DEFAULTS,
  FORMATS,
  NOTIFY,
  ALERTS,
  LABELS,
  UNITS,
} from "common/consts";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";
import { MAP_ACTIONS } from "actions/MapActions";
import { PAGE_ACTIONS } from "actions/PageActions";
import { TRACK_ANIMATION_ACTIONS } from "actions/TrackAnimationActions";
import { TRACK_ACTIONS } from "actions/TrackActions";
import { API_Map } from "apihandler/Map";

import "./track.css";

class TrackTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecord: false,
      showKm: false,
      activeChip: 7,
    };
  }

  componentDidMount() {
    const { childRef } = this.props;
    childRef(this);
  }

  componentWillUnmount() {
    const { childRef } = this.props;
    childRef(undefined);
  }

  onFilterTrackDuration = (type) => {
    const { frmval, toval } = dateFunction.getFromToVal(type, FORMATS.SQL_DATE);
    this.props.setTrackDate({
      fdt: frmval,
      tdt: toval,
    });
    this.props.setSelectedTrackDuration({ selectedTrackDuration: type });
  };

  showTrack = () => {
    const { fdt, tdt, stopTime } = this.props.trackReducer;
    const { selectedVehicle } = this.props.mapReducer;
    const args = {
      imei: selectedVehicle,
      fromdate: fdt,
      todate: tdt,
      stoptime: stopTime,
    };
    if (args.imei === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        commonJs.getDefaultDismissBtn()
      );
      return;
    }

    let diff = dateFunction.getDateDiff(fdt, tdt);
    if (diff.days > DEFAULTS.DEFAULT_TRACK_DAYS_LIMIT) {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        "Track days limit exceeded: " + DEFAULTS.DEFAULT_TRACK_DAYS_LIMIT,
        commonJs.getDefaultDismissBtn()
      );
      return;
    }

    this.props.setLoading({
      loadingPopover: true,
    });

    let self = this;
    API_Map.getVehicleTrack(args)
      .then((res) => {
        const { Result, Response } = res;
        self.props.setLoading({
          loadingPopover: false,
        });
        if (Result === 200 && Response) {
          const {
            route_details,
            route_points,
            route_summary,
            route_parkings,
          } = Response;
          console.log("track: ", Response);
          self.props.setTrackData({
            routeDetails: route_details,
            routePoints: route_points,
            routeSummary: route_summary,
            routeParkings: route_parkings,
          });
          self.props.setTrackIndex({
            trackIndex: 0,
          });
          if (route_points.length > 0) {
            // self.props.updateMapRegion(
            //   route_points[0].latitude,
            //   route_points[0].longitude,
            //   DEFAULTS.TRACK_ZOOM
            // );
            if (!self.props.pageReducer.trackBottomBar) {
              self.props.toggleBottomBar({ trackBottomBar: true });
            }
            // Fit Bounds
            self.props.trackFitBounds();
          } else {
            self.props.setLoading({
              loadingPopover: false,
            });
            commonJs.showNotify(
              self.props,
              NOTIFY.WARNING,
              ALERTS.NO_RECORDS,
              commonJs.getDefaultDismissBtn()
            );
          }
        } else {
          self.props.setLoading({
            loadingPopover: false,
          });
          commonJs.showNotify(
            self.props,
            NOTIFY.WARNING,
            res.Reason,
            commonJs.getDefaultDismissBtn()
          );
        }
        console.log("track: ", res.data);
      })
      .catch((err) => {
        console.log("track: ", err);
        self.props.setLoading({
          loadingPopover: false,
        });
        commonJs.showNotify(
          self.props,
          NOTIFY.WARNING,
          ALERTS.INTERNAL_SERVER_ERROR,
          commonJs.getDefaultDismissBtn()
        );
      });
  };

  showKmTravelled = () => {
    const { fdt, tdt, stopTime } = this.props.trackReducer;
    const { selectedVehicle } = this.props.mapReducer;
    const { uid } = this.props.loginReducer;
    const args = {
      imei: selectedVehicle,
      uid,
      fdt,
      tdt,
      stopTime,
    };

    if (args.imei === "") {
      commonJs.showNotify(
        this.props,
        NOTIFY.WARNING,
        ALERTS.PLEASE_SELECT_VEHICLE,
        commonJs.getDefaultDismissBtn()
      );
      return;
    }

    this._kmTravelled.toggleDialog(true, args);
  };

  onChangeVehicle = (value) => {
    this.props.setTrackData({
      routeDetails: {},
      routePoints: [],
      routeSummary: [],
      routeParkings: [],
    });
    if (value !== null) {
      this.props.setSelectedVehicle({
        selectedVehicle: value.id,
      });
    } else {
      this.props.setSelectedVehicle({
        selectedVehicle: "",
      });
    }
  };

  handleDateChange = (type, dt) => {
    let dtformat = "YYYY-MM-DD HH:mm";
    dt = dateFunction.getFormatedDate(dt, FORMATS.SQL_DATE, dtformat);
    if (type === "from") {
      dt += ":00";
    } else {
      dt += ":59";
    }
    if (type === "from") {
      this.props.setTrackFromDate({
        dt: dt,
      });
    } else {
      this.props.setTrackToDate({
        dt: dt,
      });
    }
  };

  onChangeStoptime = (value) => {
    this.props.setTrackStoptime({
      stopTime: value,
    });
  };

  render() {
    const {
      selectedTrackDuration,
      fdt,
      tdt,
      routeSummary,
      routeDetails,
    } = this.props.trackReducer;

    const { tmpVehicles, selectedVehicle } = this.props.mapReducer;

    const { trackBottomBar } = this.props.pageReducer;

    let vehicles = tmpVehicles.map((v) => {
      return { id: v.imei, name: v.name };
    });

    const chips = DEFAULTS.TRACK_DURATIONS;

    let vehicle = tmpVehicles.filter((v) => {
      return v.imei === selectedVehicle;
    });

    if (vehicle.length > 0) {
      vehicle = {
        id: vehicle[0].imei,
        name: vehicle[0].name,
      };
    } else {
      vehicle = {};
    }

    console.log("tracktab: ", vehicle);

    return (
      <div className="trackVehicle">
        <AutoComplete
          id={"trackVehicles"}
          label={"Select Vehicle"}
          list={vehicles}
          style={{ marginBottom: 15, marginTop: 15 }}
          onChange={this.onChangeVehicle}
          defaultValue={vehicle}
          multiple={false}
          size="small"
        />
        <div className="uidatepicker">
          <UiDatePicker
            label="From Date :"
            dt={fdt}
            type="from"
            disablePast={false}
            disableFuture={true}
            disabled={false}
            datepicker={false}
            maxDate={dateFunction.getDate("thismonth", "to")}
            handleDateChange={this.handleDateChange}
          />
          <div
            style={{
              width: "15%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowForward />
          </div>
          <UiDatePicker
            label="To Date :"
            dt={tdt}
            type="to"
            disablePast={false}
            disableFuture={false}
            disabled={false}
            maxDate={dateFunction.getDate("thismonth", "to")}
            handleDateChange={this.handleDateChange}
          />
        </div>

        <p className="label bold mB-10">Select track duration: </p>
        {chips.map((chip, index) => {
          return (
            <FleetChip
              label={chip.title}
              className={
                "chip" + (selectedTrackDuration === chip.value ? " active" : "")
              }
              onClick={() => this.onFilterTrackDuration(chip.value)}
              key={"chip-" + index}
            />
          );
        })}

        <div id="stopsDiv">
          <div>
            <p className="label stopsLabel bold">Stops: </p>
            <FleetSelect
              idName="trackStops"
              emptySelect={false}
              defaultValue={DEFAULTS.DEFAULT_TRACK_STOPTIME}
              items={DEFAULTS.TRACK_STOPTIMES}
              onChange={this.onChangeStoptime}
            />
          </div>
          <div className="trackBtn">
            <Btn
              onClick={this.showTrack}
              className={this.state.showRecord ? "active" : ""}
            >
              Show Track
            </Btn>
            {routeDetails.length > 0 && (
              <Btn
                onClick={() =>
                  this.props.toggleBottomBar({
                    trackBottomBar: !trackBottomBar,
                  })
                }
                className={this.state.showKm ? "active" : ""}
              >
                {trackBottomBar ? "Hide " : "Show "} Graph
              </Btn>
            )}
            {routeDetails.length > 0 && (
              <Btn
                onClick={this.showKmTravelled}
                className={this.state.showKm ? "active" : ""}
              >
                KMs Travelled
              </Btn>
            )}
          </div>
        </div>

        {routeDetails.length > 0 && (
          <div id="trackSummaryBox">
            <Tooltip title={LABELS.TOTAL_KMS} placement="bottom">
              <div>
                <KmsIcon />
                <h4>
                  {Number(routeDetails[0].route_length).toFixed(1) +
                    " " +
                    UNITS.DISTANCE}
                </h4>
              </div>
            </Tooltip>
            <Tooltip title={LABELS.MOVE_DURATION} placement="bottom">
              <div>
                <MoveDurationIcon />
                <h4>
                  {dateFunction.convSecondsToString(
                    routeDetails[0].move_duration
                  )}
                </h4>
              </div>
            </Tooltip>
            <Tooltip title={LABELS.STOP_DURATION} placement="bottom">
              <div>
                <StopDurationIcon />
                <h4>
                  {dateFunction.convSecondsToString(
                    routeDetails[0].stop_duration
                  )}
                </h4>
              </div>
            </Tooltip>
          </div>
        )}

        <div id="trackTimeline">
          <TrackTimeline
            routeSummary={routeSummary}
            updateMapRegion={this.props.updateMapRegion}
          />
        </div>

        <KmTravelled childRef={(ref) => (this._kmTravelled = ref)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
    mapReducer: state.mapReducer,
    loginReducer: state.loginReducer,
    pageReducer: state.pageReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleBottomBar: (values) => {
      dispatch({
        type: PAGE_ACTIONS.TOGGLE_TRACK_BOTTOM_BAR,
        values,
      });
    },
    setSelectedTrackDuration: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_SELECTED_TRACK_DURATION,
        values,
      });
    },
    setTrackDate: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_DATE,
        values,
      });
    },
    setTrackFromDate: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_FROM_DATE,
        values,
      });
    },
    setTrackToDate: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_TO_DATE,
        values,
      });
    },
    setTrackStoptime: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_STOP_TIME,
        values,
      });
    },
    setSelectedVehicle: (values) => {
      dispatch({
        type: MAP_ACTIONS.SELECT_VEHICLE,
        values,
      });
    },
    setTrackData: (values) => {
      dispatch({
        type: TRACK_ACTIONS.SET_TRACK_DATA,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
    setTrackIndex: (values) => {
      dispatch({
        type: TRACK_ANIMATION_ACTIONS.SET_TRACK_INDEX,
        values,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(TrackTab));
