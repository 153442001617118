import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import {
  MoreVertRounded as Menu,
  BatteryChargingFull as BatteryIcon,
} from "@material-ui/icons";
import { connect } from "react-redux";

import Img from "components/ui/image/Img";
import TrackMenu from "../../tracktab/trackmenu/TrackMenu";

import { CONFIG, DEFAULTS, PRIVILEGES } from "common/consts";
import { commonJs } from "common/common";
import { dateFunction } from "common/datefunctions";

import gprs from "images/leftBarImages/wifi.svg";
import gps from "images/leftBarImages/gps.svg";
import gprsoff from "images/leftBarImages/wifi_off.svg";
import gpsoff from "images/leftBarImages/gps_off.svg";
import battery from "images/leftBarImages/battery.svg";
import batteryoff from "images/leftBarImages/batteryoff.svg";
import ignition from "images/leftBarImages/key.svg";
import ignitionoff from "images/leftBarImages/keyoff.svg";
import tempIcon from "images/leftBarImages/temp.svg";
import fuelIcon from "images/leftBarImages/fuel.svg";
import humidityIcon from "images/leftBarImages/humidity.svg";
import alcoholIcon from "images/leftBarImages/alcohol.svg";

class VehicleRow extends Component {
  showVehicleMenu = (imei) => {
    let prevEl = document.querySelectorAll(".menulist.active");
    if (prevEl.length > 0) {
      if (prevEl[0].id !== "voptions-" + imei) {
        prevEl[0].classList.remove("active");
      }
    }
    document.getElementById("voptions-" + imei).classList.toggle("active");
  };

  selectVehicle = (imei) => {
    let selectedRow = localStorage.selectedRow;
    if (selectedRow !== undefined && selectedRow !== "") {
      let targetDiv = document.getElementById("vehiclerow-" + selectedRow);
      if (targetDiv !== null) {
        targetDiv.classList.remove("selected");
      }
    }
    if (document.getElementById("vehiclerow-" + imei)) {
      document.getElementById("vehiclerow-" + imei).classList.add("selected");
    }
    localStorage.selectedRow = imei;
    const { latitude, longitude } = this.props.vehicle;
    this.props.updateMapRegion(latitude, longitude, DEFAULTS.VEHICLE_MAX_ZOOM);
    commonJs.clickMarkerIcon(imei);
  };

  render() {
    const {
      slno,
      imei,
      vehicle,
      closeTrackMenu,
      onLoad,
      divstyle,
      switchTab,
      shareVehicle,
      loginReducer,
      mapReducer,
    } = this.props;
    const { privileges, role } = loginReducer;
    const { userGroups } = mapReducer;

    const { vstatus, vstatusText } = commonJs.getVehicleStatus(vehicle);

    const vsensors = commonJs.getVehicleSensors(vehicle.sensors);

    const vehicleIgn = commonJs.getVehicleIgnition(vehicle);

    const attributes = JSON.parse(vehicle.attributes);

    const hideTemp = commonJs.hasPrivilege(
      PRIVILEGES.HIDE_TEMP,
      role,
      privileges
    );

    return (
      <div
        id={"vehiclerow-" + imei}
        data-vehicle={imei}
        className={"vehiclerow"}
        style={divstyle}
        onLoad={onLoad}
      >
        <div className="vehicleicon" onClick={() => this.selectVehicle(imei)}>
          <Img src={CONFIG.BASE_URL + vehicle.icon} />
        </div>
        <div className="vehicledata" onClick={() => this.selectVehicle(imei)}>
          <h4>
            <span className="vehiclename">{slno + ". " + vehicle.name}</span>
            {vehicle.groupId != 0 && (
              <span className="vehiclegroup">
                <span className="vehicleGroupText">
                  {userGroups[vehicle.groupId]
                    ? userGroups[vehicle.groupId].name
                    : ""}
                </span>
              </span>
            )}
            <span className={"vehiclestatus " + vstatus}>{vstatusText}</span>
          </h4>
          <p className="vtime">
            {dateFunction.getFormatedDate(vehicle.objectlogtime)}
          </p>
          <p className="vaddress">{vehicle.address}</p>
          <ul>
            {/* GPRS */}
            <li title={vehicle.gprs ? "GPRS: ON" : "GPRS: OFF"}>
              <Img src={vehicle.gprs ? gprs : gprsoff} className="smallicon" />
            </li>

            {/* GPS */}
            <li title={vehicle.gps ? "GPS: ON" : "GPS: OFF"}>
              <Img src={vehicle.gps ? gps : gpsoff} className="smallicon" />
            </li>

            {/* Battery */}
            {attributes.batteryLevel ? (
              <li title={"Battery"}>
                <BatteryIcon
                  className="smallicon"
                  style={{ color: "#066ff0" }}
                />
                <span className="text">
                  {" " + attributes.batteryLevel + "%"}
                </span>
              </li>
            ) : (
              vehicle.battery != null && (
                <li title={vehicle.battery ? "Battery: ON" : "Battery: OFF"}>
                  <Img
                    src={vehicle.battery ? battery : batteryoff}
                    className="smallicon"
                  />
                </li>
              )
            )}

            {/* Ignition */}
            {vehicleIgn !== null && (
              <li title={vehicleIgn ? "Ignition: ON" : "Ignition: OFF"}>
                <Img
                  src={vehicleIgn ? ignition : ignitionoff}
                  className="smallicon"
                />
              </li>
            )}

            {/* Load All Sensors Values */}
            {vsensors.map((sensor, indx) => {
              if (sensor.type === "temp") {
                return (
                  hideTemp === false && (
                    <li
                      key={"vehiclesensor-" + indx}
                      title={sensor.name + ": " + sensor.value}
                    >
                      <Img src={tempIcon} className="smallicon" />
                      <span className="text">{sensor.value}</span>
                    </li>
                  )
                );
              } else if (sensor.type === "fuel") {
                return (
                  <li
                    key={"vehiclesensor-" + indx}
                    title={sensor.name + ": " + sensor.value}
                  >
                    <Img src={fuelIcon} className="smallicon" />
                    <span className="text">{sensor.value}</span>
                  </li>
                );
              } else if (sensor.type === "humidity") {
                return (
                  <li
                    key={"vehiclesensor-" + indx}
                    title={sensor.name + ": " + sensor.value}
                  >
                    <Img src={humidityIcon} className="smallicon" />
                    <span className="text">{" " + sensor.value}</span>
                  </li>
                );
              } else if (sensor.type === "alcohol") {
                return (
                  <li
                    key={"vehiclesensor-" + indx}
                    title={sensor.name + ": " + sensor.value}
                  >
                    <Img src={alcoholIcon} className="smallicon" />
                    <span className="text">{" " + sensor.value}</span>
                  </li>
                );
              } else {
                // Other Sensors
                return (
                  <li key={"sensor-" + indx}>
                    <span className="text">
                      {sensor.name + ": " + sensor.value}
                    </span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <div className="vehiclemenu">
          <IconButton onClick={() => this.showVehicleMenu(imei)} title="Menu">
            <Menu />
          </IconButton>
          <TrackMenu
            imei={imei}
            closeTrackMenu={closeTrackMenu}
            switchTab={switchTab}
            shareVehicle={shareVehicle}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapReducer: state.mapReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleRow);
