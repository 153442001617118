const PRODUCTION_ENV = true;

export const CONFIG = {
  SITE_NAME: "State Transport Authority, Odisha",
  VERSION: "2.1.9",
  WEBSITE_URL: "https://staodisha.vtrack.live",
  BASE_URL: "https://fleetlogix.vaschan.com",
  CPANEL_API_URL: PRODUCTION_ENV
    ? "https://fleetapi.vaschan.com"
    : "http://fleetapis.acegps.cloud",
  WS_URL: "wss://fleetlogix.vaschan.com/api/v2/ws",
  REPORTS_URL: "https://staodishareports.vtrack.live",
  FLEETSTATUS_URL: "https://fleetstatus.vaschan.com",
  CPANEL_URL: "/cpanel",
  GOOGLE_MAP_REDIRECTION_LINK: "https://www.google.com/maps?q=",
  PLAYSTORE_ID:
    "https://play.google.com/store/apps/details?id=cloud.acegps.track",
  APPSTORE_ID: "https://apps.apple.com/in/app/",
  FORGOT_PASSWORD_URL: "/forgotpassword",
  LOGIN_TEXT_P1:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  LOGIN_TEXT_P2:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  MAP_PRINT_SIZE: "A4Landscape",
  GOOGLE_KEY: ["AIzaSyAPChsaBqyb19gx9JzmJiWHjvFrwbWPVI0"],
  MAP_BOX_TILE_URL_STREETS:
    "https://api.tiles.mapbox.com/v4/mapbox.streets/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoibml0aGluZG4iLCJhIjoiY2prYXBpaWgyMHB3NzNxcGQ4ZDQzaTEydCJ9.nL5PzNydbdVmrPUIPnGoCA",
  MAP_BOX_TILE_URL_SATELLITE:
    "https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoibml0aGluZG4iLCJhIjoiY2prYXBpaWgyMHB3NzNxcGQ4ZDQzaTEydCJ9.nL5PzNydbdVmrPUIPnGoCA",
  OSM_TILE_URL: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  GOOGLE_STREETS_MAP_URL:
    "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_STREETS_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=m,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_SATELLITE_MAP_URL:
    "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_SATELLITE_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=s,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_TERRAIN_MAP_URL:
    "https://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_TERRAIN_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=t,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_HYBRID_MAP_URL:
    "https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga",
  GOOGLE_HYBRID_MAP_WITH_TRAFFIC_URL:
    "https://mt0.google.com/vt/lyrs=y,traffic&hl=en&x={x}&y={y}&z={z}&s=Ga",
  // https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}
  BUGSNAG_KEY: "a04da6004a00962981ac58aa3851deee",
  DOWNLOAD_APP: "DOWNLOAD THE APP",
  ROLE_SUPERADMIN: "superadmin",
  ROLE_ADMIN: "admin",
  MAP_CUSTOM_ARROW_SIZE: 32,
  MAP_ARROW_SIZE: 16,
  GREEN_DRIVING_PARAM: "io253",
  GREEN_DRIVING_VALUE: "io254",
  RUNNING_SPEED_MIN: 1, // So 0.5 will be ignored
  API_METHODS: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  API_TIMEOUT: 15 * 1000,
};

// TODO: Load Server Settings
export const DEFAULTS = {
  MAP_TAB: "vehicles",
  REPORT_GRID_HEIGHT: 400,
  DEFAULT_MANAGER: { id: 0, name: "No Manager" },
  DEFAULT_GROUP: { id: 0, name: "Ungrouped", vcount: 0 },
  USER_LAT_LNG: "12.9716,77.5946",
  DEFAULT_PAGE_LENGTH: 100000,
  PAGE_LENGTH_REPORTS: 100000,
  REPORTS_PAGE_LENGTH: 10, // For Data Tables
  CPANEL_GRID_PAGE_LENGTH: 20, // For DevExpressGrid
  SEARCH_STR_LENGTH: 1,
  POI_POINT_VISIBLE: true,
  POI_ZONE_COLOR: "#6B6BDE",
  POI_ZONE_VISIBLE: true,
  POI_ZONE_NAME_VISIBLE: true,
  TOOLTIP_PLACEMENT: "bottom",
  GRID_RELOAD_TIMEOUT: 1, // 1 Second
  LEFT_BAR_VARIENT: "persistent",
  GOOGLE_MAP: true,
  TRACK_BOTTOM_BAR_HEIGHT: 150,
  TRACK_MESSAGES_HEIGHT: 192,
  TRACK_LINE_THICKNESS: 2,
  TRACK_LINE_COLOR: "#ff0000c7",
  DEFAULT_VEHICLE_ICON: "/img/markers/objects/31.png",
  DEFAULT_LAT: 19.787380181986222,
  DEFAULT_LNG: 73.19091796875001,
  SHOW_LABELS_ZOOM: 8,
  DEFAULT_ZOOM: 5,
  DEFAULT_MAP: 1,
  DEFAULT_MAP_TPARTY: 0,
  DEFAULT_VEHICLE_STATUS: 0,
  DEFAULT_TRACK_STOPTIME: 10,
  DEFAULT_TRACK_DAYS_LIMIT: 7,
  DEFAULT_ALERT_TYPE: 2,
  MARKER_WIDTH: 16,
  MARKER_HEIGHT: 16,
  MAX_ZOOM: 18,
  VEHICLE_MAX_ZOOM: 18,
  GOOGLE_SEARCH_MAX_ZOOM: 16,
  POI_POINT_ZOOM: 16,
  TRACK_ZOOM: 18,
  TRACK_SPEED: 4,
  MIN_ZOOM: 4,
  VEHICLES_TIMER: 15, // In Seconds
  ONLINEUSERS_TIMER: 30 * 1000, // In Seconds
  loadArr: ["Other Issues", "In Transit", "Loaded", "Placed", "Empty", ""],
  SORT_BY: "vehicle asc",
  DEFAULT_TAIL_POINTS: "7",
  USER_NOTIFICATION_CONF: [
    { id: "Green Driving", name: "Green Driving" },
    { id: "Sensor", name: "Sensor" },
    { id: "Zone", name: "Zone" },
    { id: "Power", name: "Power" },
    { id: "Alarm", name: "Alarm" },
    { id: "Ignition", name: "Ignition" },
  ],
  ODOMETER_TYPES: [
    {
      id: "gps",
      text: "GPS",
    },
    {
      id: "odo",
      text: "Absolute odometer (odo)",
    },
    {
      id: "odor",
      text: "Relative odometer (odor)",
    },
  ],
  ENGINE_HOUR_TYPES: [
    {
      id: "acc",
      text: "ACC",
    },
    {
      id: "engh",
      text: "Absolute engine hours (engh)",
    },
    {
      id: "enghr",
      text: "Relative engine hours (enghr)",
    },
  ],
  GPS_DEVICES: [
    {
      id: "Coban GPS102",
      text: "Coban GPS102",
    },
    {
      id: "Coban GPS103",
      text: "Coban GPS103",
    },
    {
      id: "Coban GPS104",
      text: "Coban GPS104",
    },
    {
      id: "Coban GPS106",
      text: "Coban GPS106",
    },
    {
      id: "Coban GPS107",
      text: "Coban GPS107",
    },
    {
      id: "Coban GPS301",
      text: "Coban GPS301",
    },
    {
      id: "Coban GPS302",
      text: "Coban GPS302",
    },
    {
      id: "Coban GPS303",
      text: "Coban GPS303",
    },
    {
      id: "Coban GPS304",
      text: "Coban GPS304",
    },
    {
      id: "Coban GPS305",
      text: "Coban GPS305",
    },
    {
      id: "Coban GPS306",
      text: "Coban GPS306",
    },
    {
      id: "Mobile (Android)",
      text: "Mobile (Android)",
    },
    {
      id: "Mobile (iOS)",
      text: "Mobile (iOS)",
    },
    {
      id: "Other",
      text: "Other",
    },
  ],
  DEFAULT_STOPTIMES: [
    {
      id: "5",
      name: "5 Minutes",
    },
    {
      id: "10",
      name: "10 Minutes",
    },
    {
      id: "15",
      name: "15 Minutes",
    },
    {
      id: "30",
      name: "30 Minutes",
    },
    {
      id: "60",
      name: "60 Minutes",
    },
  ],
  DEFAULT_SENSOR_TYPES: [
    {
      id: "acc",
      text: "Ignition (ACC)",
    },
    {
      id: "di",
      text: "Digital Input",
    },
    {
      id: "do",
      text: "Digital Output",
    },
    {
      id: "fuel",
      text: "Fuel Level",
    },
    {
      id: "temp",
      text: "Temperature",
    },
    {
      id: "ble",
      text: "Bluetooth Low Energy",
    },
    {
      id: "humidity",
      text: "Humidity",
    },
    {
      id: "load",
      text: "Load",
    },
    {
      id: "alcohol",
      text: "Alcohol",
    },
    {
      id: "cust",
      text: "Custom",
    },
  ],
  TRACK_STOPTIMES: [
    {
      text: "> 1 min",
      value: 1,
    },
    {
      text: "> 2 mins",
      value: 2,
    },
    {
      text: "> 5 mins",
      value: 5,
    },
    {
      text: "> 10 mins",
      value: 10,
    },
    {
      text: "> 20 mins",
      value: 20,
    },
    {
      text: "> 30 mins",
      value: 30,
    },
    {
      text: "> 1 hr",
      value: 60,
    },
    {
      text: "> 2 hrs",
      value: 120,
    },
    {
      text: "> 5 hrs",
      value: 300,
    },
  ],
  TRACK_DURATIONS: [
    { title: "Last hour", value: "lasthour" },
    { title: "Today", value: "today" },
    {
      title: "Yesterday",
      value: "yesterday",
    },
    {
      title: "Before 2 Days",
      value: "twodays",
    },
    {
      title: "Before 3 Days",
      value: "threedays",
    },
    {
      title: "This week",
      value: "thisweek",
    },
    {
      title: "Last week",
      value: "lastweek",
    },
  ],
  DEFAULT_MAPTYPES_LIST: [
    "OSM Map",
    "Google Streets",
    "Google Satellite",
    "Google Hybrid",
    "Map Box Streets",
    "Map Box Satellite",
  ],
  DEFAULT_VEHICLE_STATUS_LIST: [
    "All",
    "No Signal",
    "Stopped",
    "Idle",
    "Running",
  ],
  DEFAULT_VEHICLE_LOAD_STATUS: 0,
  DEFAULT_STARTUP_SCREEN: {
    label: "Vehicles",
    value: "vehicles",
    selected: true,
  },
  DEFAULT_VEHICLE_LOAD_STATUS_LIST: [
    "All",
    "Placed",
    "Loaded",
    "In Transit",
    "Other Issues",
    "Empty",
  ],
  SORT_BY_LIST: [
    {
      name: "Vehicle (Asc)",
      value: "vehicle asc",
    },
    {
      name: "Vehicle (Desc)",
      value: "vehicle desc",
    },
    {
      name: "Speed (Asc)",
      value: "speed asc",
    },
    {
      name: "Speed (Desc)",
      value: "speed desc",
    },
  ],
  ALERT_TYPES: [
    {
      text: "Sensor Alerts",
      value: 0,
      id: 0,
    },
    {
      text: "POI Alerts",
      value: 1,
      id: 1,
    },
    {
      text: "Notifications",
      value: 2,
      id: 2,
    },
    {
      text: "Ignition Alerts",
      value: 3,
      id: 3,
    },
    {
      text: "Power Unplug Alerts",
      value: 4,
      id: 4,
    },
    {
      text: "Green Driving Alerts",
      value: 6,
      id: 6,
    },
    {
      text: "Idling Alerts",
      value: 7,
      id: 7,
    },
    {
      text: "Panic or SOS Alerts",
      value: 8,
      id: 8,
    },
    {
      text: "Towing Alerts",
      value: 9,
      id: 9,
    },
    {
      text: "Accident Alerts",
      value: 10,
      id: 10,
    },
    {
      text: "Document Expiry Alerts",
      value: 11,
      id: 11,
    },
    {
      text: "Overspeed or Underspeed Alerts",
      value: 5,
      id: 5,
    },
  ],
};

export const LABELS = {
  SIGNAL_STATUS: "Signal Status",
  VEHICLE_STATUS: "Vehicle Status",
  TOP_SPEED: "Top Speed",
  PLAY_PLAYBACK: "Play",
  STOP_PLAYBACK: "Stop",
  SPEED_PLUS: "Increase Playback Speed",
  SPEED_MINUS: "Decrease Playback Speed",
  MOVE_DURATION: "Move Duration",
  STOP_DURATION: "Stop Duration",
  SKIP_STOPS: "Skip Stops",
  TOTAL_KMS: "Total Kms",
  KM_TRAVELLED: "Km Travelled",
  RUNNING: "Running",
  STOPPED: "Stopped",
  COMPANY_NAME: "Company Name",
  COMPANY_LOGO: "Company Logo",
  EDIT_COMPANY: "Edit Company",
  ADD_COMPANY: "Add Company",
  SEARCH_BY_NAME: "Search By Name",
  SEARCH_BY_VEHICLE_NAME: "Search By Vehicle Name",
  SEARCH_BY_COMPANY_NAME: "Search By Company Name",
  SEARCH_BY_PRIVILEGE: "Search By Privilege",
  SEARCH_BY_PACKAGE: "Search By Package",
  SEARCH_BY_ROLE: "Search By Role",
  ROAD_TAX: "Road Tax",
  VEHICLE_PERMIT: "Vehicle Permit",
  VEHICLE_FITNESS: "Vehicle Fitness",
  VEHICLE_INSURANCE: "Vehicle Insurance",
  REGISTRATION_DATE: "Registration Date",
  DRIVER_LICENSE: "Driver License",
  SIGN_IN: "Sign In",
  SIGN_IN_NOTE:
    "Note: By signing in, I agree that this website can set cookies on my browser.",
  USERNAME: "Username",
  PASSWORD: "Password",
  REMEMBER_ME: "Remember me",
  LOGIN: "Login",
  LOGOUT: "Logout",
  CANCEL: "Cancel",
  OK: "OK",
  RELOAD: "Reload",
  ADD: "Add",
  EDIT: "Edit",
  DELETE: "Delete",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  EXPORT_CSV_OR_EXCEL: "Export CSV/Excel",
  SHOW: "Show",
  FORGOT_PASSWORD: "Forgot Password ?",
  MAP: "Map",
  HOME: "Home",
  REPORTS: "Reports",
  DASHBOARD: "Dashboard",
  FLEET_STATUS: "Fleet Status",
  CPANEL: "CPanel",
  CPANEL_MENU: "CPanel Menu",
  SETTINGS: "Settings",
  MAXIMIZE: "Maximize",
  FULL_SCREEN: "Full Screen",
  EXIT_FULL_SCREEN: "Exit Full Screen",
  FIT_SCREEN: "Fit Screen",
  EXIT_FIT_SCREEN: "Exit Fit Screen",
  LOCATE_MARKER: "Show Location",
  SCALE: "Scale",
  PRINT_MAP: "Print Map",
  HIDE_UNHIDE_LABELS: "Hide/Unhide Labels",
  HIDE_UNHIDE_VEHICLES: "Hide/Unhide Vehicles",
  HIDE_UNHIDE_POINTS: "Hide/Unhide Points",
  HIDE_UNHIDE_ROUTES: "Hide/Unhide Routes",
  HIDE_UNHIDE_ZONES: "Hide/Unhide Zones",
  HIDE_UNHIDE_CLUSTERS: "Hide/Unhide Clusters",
  SHOW_DATA_POINTS: "Hide/Unhide Data Points",
  SHOW_TRAFFIC: "Show Traffic",
  SAVE_CHANGES: "Save Changes",
  DISMISS: "Dismiss",
  CLICK_HERE_TO_OPEN_GOOGLE_MAP: "Click Here to Open in Google Map",
  ETA_MASTER: "ETA Master",
  COMPANIES: "Companies",
  PRIVILEGES: "Privileges",
  PACKAGES: "Packages",
  ROLES: "Roles",
  PACKAGES_ROLES: "Packages Roles",
  PACKAGES_PRIVILEGES: "Packages Privileges",
  ACTUAL: "Actual",
  ADMIN: "Admin",
  LIVE: "Live",
  LIVE_VEHICLES_INFO: "Live Vehicles Info",
  SELECT_REPORT_TYPE: "Select Report Type",
  SELECT_VEHICLES: "Select vehicles...",
  ZONE: "Zone",
  ZONES: "Zones",
  POINTS: "Points",
  ROUTES: "Routes",
  RANGE: "Range",
  TEMPERATURE: "Temperature",
  MASTERS: "Masters",
  USERS: "Users",
  VEHICLES_LIST: "Vehicles List",
  VEHICLE_NAME: "Vehicle Name",
  IMEI_NO: "IMEI Number",
  SIM_NUMBER: "SIM Number",
  SAVE: "Save",
  CLOSE: "Close",
  UNGROUPED: "Ungrouped",
};

export const HELPER_TEXT = {
  PLEASE_ENTER_USERNAME: "Please enter username",
  PLEASE_ENTER_PASSWORD: "Please enter password",
};

export const CONFIRMS = {
  CONFIRM_LOGOUT: "Are you sure want to logout..?",
};

export const ALERTS = {
  PLEASE_SELECT_VEHICLE: "Please select vehicle..!",
  PLEASE_SELECT_STOPTIME: "Please select stoptime..!",
  USERNAME_AND_PASSWORD_EMPTY: "Username and Password are empty..!",
  USERNAME_EMPTY: "Username is empty..!",
  PASSWORD_EMPTY: "Password is empty..!",
  LOGOUT_SUCESSSFUL: "Logout successful, redirecting to login page...",
  LOGIN_SUCESSSFUL: "Login Successful..!",
  LOGIN_UNSUCESSSFUL: "Login Unsuccessful..!",
  SESSION_TIMEOUT: "Session Timeout..!",
  INTERNAL_SERVER_ERROR: "Internal Server Error..!",
  TO_LOCATION_ERROR: "To location empty Error..!",
  NO_INTERNET: "No Internet or Network Error..!",
  NO_RECORDS: "No Records Found..!",
  API_KEY_MISSING: "API Key is missing..!",
  API_OBJECTS_MISSING: "Vehicle imei is missing..!",
  PLEASE_SELECT_IDLE_HOURS: "Please select Hours..!",
  PLEASE_SELECT_ZONE_A: "Please select zone A..!",
  PLEASE_SELECT_ZONE_B: "Please select zone B..!",
  PLEASE_SELECT_MONTH: "Please select month..!",
  PLEASE_SELECT_YEAR: "Please select year..!",
  PLEASE_SELECT_ZONE: "Please select zone..!",
  PLEASE_SELECT_RANGE: "Please select range..!",
  PLEASE_SELECT_ROUTE: "Please select route..!",
  PLEASE_ENTER_SPEEDLIMIT: "Please enter speedlimit..!",
  PLEASE_SELECT_SPEED: "Please select speed..!",
  PLEASE_SELECT_KM: "Please select Km..!",
};

export const WARNINGS = {
  COMPANY_NAME_SHOULD_NOT_BE_EMPTY: "Company name should not be empty..!",
  ZONE_NAME_SHOULD_NOT_BE_EMPTY: "Zone name should not be empty..!",
  ZONE_IS_INCOMPLETE: "Zone is Incomplete: Please complete the zone points..!",
  ZONE_POINTS_SHOULD_BE_GREATER_THAN_2:
    "Zone Points should be greater than 2..!",
  POINT_NAME_SHOULD_NOT_BE_EMPTY: "Point name should not be empty..!",
  POINT_IS_NOT_PLACED_ON_MAP: "Please click on the map to place the Point..!",
  PLEASE_SELECT_COMPANY: "Please select user's company..!",
  PLEASE_SELECT_MANAGER: "Please select user's manager..!",
  PLEASE_SELECT_PACKAGE: "Please select user's package..!",
  PLEASE_SELECT_ROLE: "Please select user's role..!",
};

export const ERRORS = {
  ZONE_NOT_DELETED: "Error: Zone is not deleted..!",
  ZONE_NOT_SAVED: "Error: Zone not saved successfully..!",
  POINT_NOT_DELETED: "Error: Point is not deleted..!",
  POINT_NOT_SAVED: "Error: Point not saved successfully..!",
  ROUTE_NOT_DELETED: "Error: Route is not deleted..!",
  ROUTE_NOT_SAVED: "Error: Route not saved successfully..!",
  RECORD_NOT_SAVED: "Error: Record not saved successfully..!",
  RECORD_NOT_DELETED: "Error: Record not deleted successfully..!",
  INTERNAL_ERROR: "Internal Server Error..!",
};

export const SUCCESS_MSG = {
  RECORD_SAVED: "Record saved successfully..!",
  RECORD_NOT_SAVED: "Record not saved ..!",
  COMPANY_SAVED: "Company saved successfully..!",
  ZONE_SAVED_SUCCESSFULLY: "Zone saved successfully..!",
  ZONE_DELETED_SUCCESSFULLY: "Zone deleted successfully..!",
  POINT_SAVED_SUCCESSFULLY: "Point saved successfully..!",
  POINT_DELETED_SUCCESSFULLY: "Point deleted successfully..!",
  ROUTE_SAVED_SUCCESSFULLY: "Route saved successfully..!",
  ROUTE_DELETED_SUCCESSFULLY: "Route deleted successfully..!",
  RECORD_DELETED_SUCCESSFULLY: "Record deleted successfully..!",
};

export const COLORS = {
  PRIMARY: "#6B6BDE",
  SECONDARY: "#35354A",
  ICON_COLOR: "#2A2A39",
  WHITE: "#FFF",
  RED: "red",
  BLACK: "#000",
  DIM_GRAY: "#CCC",
  PROGRESS_BAR_COLOR: "primary",
  GREEN: "#00e500",
  DARK_GREEN: "#00a500",
  TRANSP: "transparent",
  YELLOW: "#FFAA00",
};

export const UNITS = {
  TEMP: "°C",
  SPEED: "km/h",
  DISTANCE: "Kms",
  FUEL: "Ltrs.",
  PERCENT: "%",
  ANGLE: "°",
  Altitude: "m",
};

export const FORMATS = {
  SQL_DATE: "YYYY-MM-DD HH:mm:ss",
  DATE: "DD-MM-YYYY HH:mm:ss",
  DATE_ONLY: "DD-MM-YYYY",
  DATE_PICKER_FORMAT: "dd-MM-yyyy",
  REPORT_TITLE_DATE: "MMM DD, YYYY",
  REPORT_DATE_WITH_TIME: "MMM DD, YYYY HH:mm:ss",
  MONTH_ONLY: "MMMM",
  MONTH_AND_YEAR_ONLY: "MMMM - YYYY",
  GRAPH_TIME: "HH:mm",
};

export const NOTIFY = {
  WARNING: "warning",
  SUCCESS: "success",
  ERROR: "error",
  AUTO_HIDE_DURATION: 3000,
};

export const LOCAL_STORAGE = {
  USER_DATA: "userInfo",
  USER_SETTINGS: "userSettings",
  MAP: "map",
  MAP_TAB: "mapTab",
  POI_TAB: "poiTab",
  MAP_SETTINGS: "mapSettings",
  PAGE_SETTINGS: "pageSettings",
  CALL_USER: "callUser",
  TOKEN: "token",
};

export const REPORT_NAMES = {
  VEHICLE_INFO_REPORT: "Vehicles Information Report",
  JOURNEY_REPORT: "Journey Report",
  KM_REPORT: "KM Summary Report",
  TEMP_REPORT: "Temperature Report",
  ZONEINOUT_REPORT: "Zone In Out Report",
  FUEL_REPORT: "Fuel Report",
  DIALY_TRAVEL_REPORT: "Daily Travel Report",
  TRAVEL_REPORT: "Travel Report",
  TRIP_REPORT: "Trip Report",
  DRIVING_REPORT: "Driving Report",
  LIVE_REPORT: "Live Report",
  LIVE_NO_SIGNAL: "Live No Signal Report",
  IDLE_REPORT: "Idle Report",
  A_TO_B_TRIP_REPORT: "A - B Trip Report",
  IGNITION_REPORT: "Ignitation Report",
  LOCATION_REPORT: "Location Report",
  POINT_IN_OUT_REPORT: "Point In Out Report",
  VEHICLE_DOCUMENT_EXPIRY_REPORT: "Vehicle Document Expiry Report",
  ROUTE_TRIP_REPORT: "Route Trip Report",
  A_TO_B_ZONE_TRIP_REPORT: "A - B Zone Trip Report",
  ROUND_TRIP_KMS_REPORT: "Round Trip Kms Report",
  NON_JOURNEY_REPORT: "Non Journey Report",
  DISTANCE_BY_HOUR_REPORT: "Distance (Kms) by Hour Report",
  CURRENT_MONTH_REPORT: "Current Month Report",
  MONTHLY_ANALYSIS_REPORT: "Monthly Analysis Report",
  PROXIMITY_REPORT: "Proximity Report",
  CONSOLIDATED_REPORT: "Consolidated Report",
  ANALYTICAL_REPORT: "Analytical Report",
  OVER_SPEED_REPORT: "Over Speed Report",
  GREEN_DRIVING_DETAILS_REPORT: "Green Driving Details",
  FUEL_CONSUMPTION_REPORT: "Fuel Consumption Report",
  ALCOHOL_VALUE_REPORT: "Alcohol Values Report",
  TEMP_GRAPH: "Temperature Graph",
  POWER_UNPLUG_REPORT: "Power Unplug Report",
  SIGNAL_REPORT: "Signal Report",
  TRAVEL_HISTORY_REPORT: "Travel History Report",
  ROUTE_DEVIATION_REPORT: "Route Deviation Report",
  UNDERSPEED_REPORT: "Underspeed Report",
  TEMPERATURE_BY_HOUR_REPORT: "Temperature (°C) by Hour Report",
  TEMPERATURE_EXCEPTION_REPORT: "Temperature Exception Report",
  ETA_REPORT: "ETA Report",
  LOAD_SENSOR_REPORT: "Load Sensor Report",
  TEMPERATURE_COLD_STORAGE_GRAPH_REPORT:
    "Temperature Cold Storage Graph Report",
  NIGHT_DRIVE_REPORT: "Night Drive Report",
  NON_PERFORMANCE_REPORT: "Non - Performance Report",
  GROUPWISE_TEMPERATURE_BY_HOUR_REPORT: "Groupwise Temperature by Hour Report",
  ETA_SUMMARY_REPORT: "ETA Summary Report",
  FUEL_TIMELINE_REPORT: "Fuel Timeline Report",
  TEMPERATURE_GRAPH_REPORT: "Temperature Graph Report",
  ROUND_TRIP_GRAPH: "Round Trip Graph",
};

export const REPORT_DESCRIPTION = {
  DASHBOARD: "Dashboard shows the overall statistics of the system.",
  VEHICLE_INFO_REPORT:
    "This report will show vehicle information for the selected vehicle(s).",
  JOURNEY_REPORT:
    "This report will show journey details of a vehicle for the selected date range.",
  TRAVEL_REPORT:
    "This report will show travel details of a vehicle for the selected date range.",
  KM_REPORT:
    "This report will show kilometer details of the vehicle(s) for the selected date range.",
  TEMP_REPORT:
    "This report will show journey temperature details of a vehicle for the selected date range.",
  ZONEINOUT_REPORT:
    "This report will show zone in out information of a vehicle for the selected date range.",
  FUEL_REPORT:
    "This report will show fuel information of a vehicle for the selected date range.",
  DIALY_TRAVEL_REPORT:
    "This report will shows the consolidated journey information of the vehicles for the selected date range.",
  DRIVING_REPORT:
    "This report will shows the driving behaviour of the selected vehicle.",
  TRIP_REPORT:
    "This report will shows the trip details of the selected vehicle for the selected date range.",
  LIVE_REPORT:
    "This report will show live journey details of the selected vehicle(s).",
  LIVE_NO_SIGNAL_REPORT: "This report will show live no signal details.",
  IDLE_REPORT: "This report will show Idle details.",
  A_TO_B_TRIP_REPORT: "This report will show A to B Trip details.",
  IGNITION_REPORT: "This report will show ignition report details.",
  LOCATION_REPORT: "This Report will show location check details",
  POINT_IN_OUT_REPORT: "This report will show point in out details",
  A_TO_B_ZONE_TRIP_REPORT: "This report will shows A to B zone trip details",
  VEHICLE_DOCUMENT_EXPIRY_REPORT:
    "This report will shows vehicle document expiry details",
  ROUTE_TRIP_REPORT: "This report will shows route trip details",
  ROUND_TRIP_KMS_REPORT: "This report will shows round trip kms details",
  NON_JOURNEY_REPORT: "This report will shows non journey details",
  DISTANCE_BY_HOUR_REPORT: "This report will shows distance by hour details",
  CURRENT_MONTH_REPORT: "This report will show the current month details.",
  MONTHLY_ANALYSIS_REPORT:
    "This report will show the monthly analysis report details.",
  PROXIMITY_REPORT: "This report will show the proximity report details.",
  CONSOLIDATED_REPORT: "This report will show consolidated details",
  NIGHT_DRIVE_REPORT: "This report will show night drive details",
  ANALYTICAL_REPORT: "This report will show analytical details",
  OVER_SPEED_REPORT: "This report will show overspeed details",
  GREEN_DRIVING_DETAILS_REPORT: "This report will show green driving details",
  FUEL_CONSUMPTION_REPORT: "This report will show fuel consumption details",
  ALCOHOL_VALUE_REPORT: "This report will show alcohol value details",
  TEMP_GRAPH: "This report will show temperature graph details",
  POWER_UNPLUG_REPORT: "This report will show power unplug report details",
  SIGNAL_REPORT: "This report will shows signal details",
  TRAVEL_HISTORY_REPORT: "This report will shows travel history details",
  ROUTE_DEVIATION_REPORT: "This report will shows route deviation details",
  UNDERSPEED_REPORT: "This report will shows underspeed report details",
  TEMPERATURE_BY_HOUR_REPORT:
    "This report will shows temperature by hour report details",
  TEMPERATURE_EXCEPTION_REPORT:
    "This report will shows temperature exception report details",
  ETA_REPORT: "This report will shows ETA report details",
  LOAD_SENSOR_REPORT: "This report will shows sensor report details",
  TEMPERATURE_COLD_STORAGE_GRAPH_REPORT:
    "This report will shows temperature cold storage graph report details",
  NON_PERFORMANCE_REPORT: "This report will shows non performance details",
  GROUPWISE_TEMPERATURE_BY_HOUR_REPORT:
    "This report will shows groupwise temperature by hour details",
  ETA_SUMMARY_REPORT: "This report will shows ETA summary details",
  FUEL_TIMELINE_REPORT: "This report will shows fuel timeline details",
  TEMPERATURE_GRAPH_REPORT: "This report will shows temperature graph details",
  ROUND_TRIP_GRAPH: "This report will shows round trip graph details",
};

export const ROLES = {
  SUPER_ADMIN: "superadmin",
  ADMIN: "admin",
  MANAGER: "manager",
  SUB_USER: "subaccount",
};

export const PRIVILEGES = {
  REPORTS: "rp",
  REPORT_ZONE_IN_OUT: "rp1",
  REPORT_TRAVEL: "rp2",
  REPORT_DAILY_TRAVEL: "rp3",
  REPORT_TRIP: "rp4",
  REPORT_DRIVE_STOP: "rp5",
  REPORT_TEMP: "rp6",
  REPORT_FUEL: "rp7",
  REPORT_DRIVING: "rp8",
  REPORT_KM_SUMMARY: "rp10",
  ALERTS: "al",
  TRACK: "trk",
  MARKER_ANIMATION: "ani",
  IMMOBILIZATION: "imbl",
  EDIT_VEHICLE: "evl",
  HIDE_TEMP: "notemp",
  POI: "poi",
  EDIT_POI: "setpoi",
  SHARE_VEHICLE: "shr",
  ETA_MASTER: "eta",
  MAP: "mp",
  LIVE_VEHICLES_INFO: "livepopup",
  ADD_VEHICLE: "adv",
};

export const CPANEL_MENUS = {
  USERS: "Users",
  MASTERS: "Masters",
  COMPANY_USERS: "Company Users",
  SUB_ACCOUNTS: "Sub Accounts",
  VEHICLES_LIST: "Vehicles List",
};

export const CPANEL_DESCRIPTION = {
  DASHBOARD: "Dashboard shows the overall statistics.",
  MASTERS: "Manage company, privileges and roles master",
  ADD_USER: "Manage Users",
  VEHICLE_LIST:
    "This page will manage the vehicle adding, editing & removing from the system.",
};
