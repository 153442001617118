import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { connect } from "react-redux";

import FleetDropDown from "../../../ui/dropdown/FleetDropDown";
import TrackSlider from "./TrackSlider";

import {
  COLORS,
  UNITS,
  DEFAULTS,
  CONFIG,
  FORMATS,
  PRIVILEGES,
} from "../../../../common/consts";
import { commonJs } from "../../../../common/common";
import { TRACK_ANIMATION_ACTIONS } from "../../../../actions/TrackAnimationActions";
import { dateFunction } from "../../../../common/datefunctions";

class TrackGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSensorParam: "speed",
    };
    this._categories = {
      1: 0,
      2: 0,
      3: 0,
    };
  }

  onChangeParam = (val) => {
    this.setState({
      selectedSensorParam: val,
    });
  };

  onGraphClick = (e) => {
    const { routePoints } = this.props.trackReducer;
    let timeVal = dateFunction.getDateFromSeconds(e.point.x);
    let graphIndex = 0;

    for (let i = 0; i < routePoints.length; i++) {
      if (routePoints[i]["time"] === timeVal) {
        graphIndex = i;
        break;
      }
    }

    this.props.setTrackIndex({
      trackIndex: graphIndex,
    });

    this.props.updateMapRegion(
      routePoints[graphIndex].latitude,
      routePoints[graphIndex].longitude,
      DEFAULTS.MAX_ZOOM
    );
  };

  getChartOptions = () => {
    const { trackReducer } = this.props;
    const { selectedSensorParam } = this.state;
    const { routePoints } = trackReducer;
    const { selectedVehicle, tmpVehicles } = this.props.mapReducer;

    let vehicle = commonJs.getVehicleFromArray(tmpVehicles, selectedVehicle);
    let sensorTypes = [
      { id: "speed", text: "Speed" },
      { id: "driving", text: "Driving" },
    ]; // Speed: Default Param

    let sensorsJson = {};
    let sensorsList = [];
    if (routePoints.length > 0 && vehicle.length > 0) {
      sensorsList = vehicle[0].sensors;
      for (let i in sensorsList) {
        sensorsJson[sensorsList[i].param] = sensorsList[i];
        sensorTypes.push({
          id: sensorsList[i].param,
          text: sensorsList[i].name,
        });
      }
    }

    let data = [];
    let seriesName = "Speed";
    let sensorUnit = UNITS.SPEED;
    let sensorMinValue = 0;
    this._categories = {
      1: 0,
      2: 0,
      3: 0,
    };
    const paramColors = {
      1: COLORS.BLACK,
      2: COLORS.RED,
      3: COLORS.YELLOW,
    };

    // Generate Chart Data
    for (let i = 0; i < routePoints.length; i++) {
      let params = routePoints[i]["params"];
      let sensorValue = 0;
      if (selectedSensorParam === "speed") {
        sensorValue = parseFloat(routePoints[i]["speed"]);
        data.push([new Date(routePoints[i]["time"]).getTime(), sensorValue]);
      } else if (selectedSensorParam === "driving") {
        // Harsh Acceleration : io253:1
        // Harsh Braking      : io253:2
        // Harsh Cornering    : io253:3
        if (routePoints[i]["params"][CONFIG.GREEN_DRIVING_PARAM]) {
          let greenDriving =
            routePoints[i]["params"][CONFIG.GREEN_DRIVING_PARAM];
          let greenDrivingValue =
            routePoints[i]["params"][CONFIG.GREEN_DRIVING_VALUE];
          data.push([
            new Date(routePoints[i]["time"]).getTime(),
            greenDrivingValue,
            { enabled: true, radius: 4, fillColor: paramColors[greenDriving] },
          ]);
          this._categories[greenDriving]++;
        }
        seriesName = "Driving Events";
      } else {
        let sensor = sensorsList.filter((s) => s.param === selectedSensorParam);
        if (sensor.length > 0) {
          sensor = sensor[0];
          seriesName = sensor.name;
          if (sensor.sensorType === "fuel") {
            sensor.value = params[sensor.param];
            sensorValue = commonJs.getFuelValue(sensor);
            if (sensorValue === "N/A") {
              sensorValue = 0;
            } else {
              sensorValue = Number(sensorValue);
            }
            sensorUnit = sensor.units;
            if (sensorUnit === "") {
              sensorUnit = UNITS.FUEL;
            }
          } else if (sensor.sensorType === "temp") {
            sensor.value = params[sensor.param];
            sensorValue = Number(sensor.value);
            if (sensor.param === "io25") {
              sensorValue = sensorValue / 100;
            }
            sensorMinValue = null;
            sensorUnit = sensor.units;
            if (sensorUnit === "" || sensorUnit === "C") {
              sensorUnit = UNITS.TEMP;
            }
          } else {
            sensor.value = params[sensor.param];
            sensorValue = Number(sensor.value);
            sensorUnit = sensor.units;
          }
          sensorValue = Number(sensorValue).toFixed(1);
          data.push([
            new Date(routePoints[i]["time"]).getTime(),
            Number(sensorValue),
          ]);
        }
      }
    }

    let self = this;

    let options = {
      chart: {
        type: "line",
        zoomType: "x",
        zoomOut: true,
        height: DEFAULTS.TRACK_BOTTOM_BAR_HEIGHT,
        marginRight: 10,
        marginBottom: 20,
        events: {
          // click: function (e) {
          //   self.onGraphClick(e, "label");
          // },
        },
      },
      lang: {
        decimalPoint: ",",
        thousandsSep: ".",
      },
      global: {
        useUTC: false,
      },
      time: {
        timezoneOffset: -330,
      },
      credits: false,
      title: {
        text: "",
      },
      subtitle: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        tickPixelInterval: 150,
        labels: {
          formatter: function () {
            return dateFunction.getDateFromSeconds(
              this.value,
              FORMATS.GRAPH_TIME
            );
          },
        },
      },
      yAxis: {
        title: {
          text:
            selectedSensorParam === "driving"
              ? seriesName
              : seriesName + " in " + sensorUnit,
        },
        plotLines: [
          {
            width: 1,
            color: COLORS.PRIMARY,
          },
        ],
        min: sensorMinValue,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          cursor: "pointer",
          events: {
            click: function (event) {
              self.onGraphClick(event);
            },
          },
        },
        area: {
          keys: ["x", "y", "marker"],
          fillColor:
            selectedSensorParam === "driving"
              ? COLORS.TRANSP
              : {
                  linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                  },
                  stops: [
                    [0, COLORS.PRIMARY],
                    [1, "#FFF"],
                  ],
                },
          lineColor: COLORS.PRIMARY,
          lineWidth: selectedSensorParam === "driving" ? 0 : 1,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          threshold: null,
          cropThreshold: 10000,
        },
      },
      tooltip: {
        pointFormat:
          selectedSensorParam === "driving"
            ? "Value: <b>{point.y}</b>"
            : seriesName + ": <b>{point.y} " + sensorUnit + "</b>",
      },
      series: [
        {
          type: "area",
          name: seriesName,
          data: data,
          color: COLORS.PRIMARY,
        },
      ],
    };

    return options;
  };

  render() {
    const { selectedSensorParam } = this.state;
    const { routePoints } = this.props.trackReducer;
    const { selectedVehicle, tmpVehicles } = this.props.mapReducer;
    const { privileges, role } = this.props.loginReducer;

    const vehicle = commonJs.getVehicleFromArray(tmpVehicles, selectedVehicle);

    let sensorTypes = [
      { id: "speed", text: "Speed" },
      { id: "driving", text: "Driving" },
    ]; // Speed: Default Param
    let sensorsList = [];
    if (routePoints.length > 0 && vehicle.length > 0) {
      sensorsList = vehicle[0].sensors;
      for (let i in sensorsList) {
        if (sensorsList[i].type === "temp") {
          const hideTemp = commonJs.hasPrivilege(
            PRIVILEGES.HIDE_TEMP,
            role,
            privileges
          );
          if (hideTemp === false) {
            sensorTypes.push({
              id: sensorsList[i].param,
              text: sensorsList[i].name,
            });
          }
        } else {
          sensorTypes.push({
            id: sensorsList[i].param,
            text: sensorsList[i].name,
          });
        }
      }
    }

    const options = this.getChartOptions();

    return (
      <div id="trackGraphWrapper">
        <div id="trackGraphFilter">
          <FleetDropDown
            list={sensorTypes}
            noemptySelection={true}
            selectedValue={selectedSensorParam}
            onChange={this.onChangeParam}
            id="trackGraphParamFilter"
          />
          <TrackSlider updateMapRegion={this.props.updateMapRegion} />
        </div>
        <div style={{ width: "100%" }}>
          {options.series[0].data.length > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              allowChartUpdate={true}
              options={options}
              style={{ height: 300 }}
              ref={(ref) => {
                this._highcharts = ref;
              }}
              id="trackChart"
            />
          ) : (
            <div
              style={{
                display: "flex",
                color: "#b5b5b5",
                fontSize: 16,
                fontWeight: 500,
                justifyContent: "center",
                alignItems: "center",
                minHeight: 100,
              }}
            >
              <h4>No Records</h4>
            </div>
          )}
          {selectedSensorParam === "driving" &&
            options.series[0].data.length > 0 && (
              <div>
                <ul className="graphInfo">
                  <li id="harshAcceration">
                    <span></span> Harsh Acceration ({this._categories[1]})
                  </li>
                  <li id="harshBraking">
                    <span></span> Harsh Braking ({this._categories[2]})
                  </li>
                  <li id="harshCornering">
                    <span></span> Harsh Cornering ({this._categories[3]})
                  </li>
                </ul>
              </div>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trackReducer: state.trackReducer,
    mapReducer: state.mapReducer,
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTrackIndex: (values) => {
      dispatch({
        type: TRACK_ANIMATION_ACTIONS.SET_TRACK_INDEX,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackGraph);
