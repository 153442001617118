import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import { Share, Directions, DepartureBoard } from "@material-ui/icons";
import { Popup } from "react-leaflet";
import { connect } from "react-redux";

import { PRIVILEGES, UNITS } from "common/consts";
import { dateFunction } from "common/datefunctions";
import { commonJs } from "common/common";
import { LABELS, ROLES } from "common/consts";

class MapPopup extends Component {
  showRoute = (lat, lng) => {
    window.open(
      "https://maps.google.com/maps?saddr=" + lat + "," + lng + "&daddr="
    );
  };

  shareLocation = () => {
    const { vehicle } = this.props;
    this.props.shareVehicle(vehicle.imei);
  };

  trackVehicle = () => {
    const { vehicle } = this.props;
    this.props.showTrack("today", vehicle.imei);
  };

  getMarkerSummary = (vehicle, data, options = true) => {
    const { sensors } = vehicle;
    const vehicleIgn = commonJs.getVehicleIgnition(vehicle);
    const sensorsList = commonJs.getVehicleSensors(sensors);
    const { vstatusText, vstatus } = commonJs.getVehicleStatus(vehicle);
    const { privileges, role } = this.props.loginReducer;
    const hideTemp = commonJs.hasPrivilege(
      PRIVILEGES.HIDE_TEMP,
      role,
      privileges
    );
    const showTrack = commonJs.hasPrivilege(PRIVILEGES.TRACK, role, privileges);
    const showPois = commonJs.hasPrivilege(PRIVILEGES.POI, role, privileges);
    const showShare = commonJs.hasPrivilege(
      PRIVILEGES.SHARE_VEHICLE,
      role,
      privileges
    );
    const isAdmin = role === ROLES.ADMIN || role === ROLES.SUPER_ADMIN;
    return (
      <tbody>
        <tr>
          <td colSpan="2">
            <h4>
              <span className="popupName">{vehicle.name}</span>
              <span className={"popupStatus " + vstatus}>{vstatusText}</span>
            </h4>
            <p className="popupAddress">{vehicle.address}</p>
          </td>
        </tr>
        <tr>
          <td className="w40p pB5">Position</td>
          <td className="w60p pB5">
            {": "}&nbsp;&nbsp;
            <a
              href={
                "//maps.google.com/maps?q=" +
                vehicle.latitude +
                "," +
                vehicle.longitude
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {vehicle.latitude.toFixed(6)}°, {vehicle.longitude.toFixed(6)}°
            </a>
          </td>
        </tr>
        {vehicle.groupName
          ? vehicle.groupName.trim() !== "" && (
              <tr>
                <td className="w40p pB5">Group Name</td>
                <td className="w60p pB5">
                  {": "}&nbsp;&nbsp;{vehicle.groupName}
                </td>
              </tr>
            )
          : null}
        <tr>
          <td className="w40p pB5">Altitude</td>
          <td className="w60p pB5">
            {": "}&nbsp;&nbsp;{vehicle.altitude + " " + UNITS.Altitude}
          </td>
        </tr>
        <tr>
          <td className="w40p pB5">Angle</td>
          <td className="w60p pB5">
            {": "}&nbsp;&nbsp;{vehicle.angle + " " + UNITS.ANGLE}
          </td>
        </tr>
        <tr>
          <td className="w40p pB5">Speed</td>
          <td className="w60p pB5">
            {": "}&nbsp;&nbsp;{vehicle.speed.toFixed(1) + " " + UNITS.SPEED}
          </td>
        </tr>
        {/* Time */}
        {vehicle.objectlogtime !== undefined && (
          <tr>
            <td className="w40p pB5">Time</td>
            <td className="w60p pB5">
              {": "}&nbsp;&nbsp;
              {dateFunction.getFormatedDate(vehicle.objectlogtime)}
            </td>
          </tr>
        )}

        {/* Idle Time */}
        {vehicle.idletime !== undefined && (
          <tr>
            <td className="w40p pB5">Time (Idle Since)</td>
            <td className="w60p pB5">
              {": "}&nbsp;&nbsp;
              {vehicle.idletime == null
                ? "-"
                : dateFunction.getFormatedDate(vehicle.idletime)}
            </td>
          </tr>
        )}

        {/* Ignition */}
        {vehicleIgn !== null && (
          <tr>
            <td className="w40p pB5">Ignition</td>
            <td className="w60p pB5">
              {": "}&nbsp;&nbsp;
              {vehicleIgn ? "ON" : "OFF"}
            </td>
          </tr>
        )}

        {/* Sensors */}
        {sensorsList.map((sensor, indx) => {
          if (sensor.type === "temp" && hideTemp) {
            return null;
          }
          return (
            <tr key={"sensortype-" + indx}>
              <td className="w40p pB5">{sensor.name}:</td>
              <td className="w60p pB5">
                {": "}&nbsp;&nbsp;
                {sensor.value}
              </td>
            </tr>
          );
        })}

        {data.distance !== undefined && (
          <tr>
            <td className="w40p pB5">Today's Travel</td>
            <td className="w60p pB5" id="popupTodayTravel">
              {": "}&nbsp;&nbsp;{data.distance.today + " " + UNITS.DISTANCE}
            </td>
          </tr>
        )}

        {data.distance !== undefined && showPois && (
          <tr>
            <td className="w40p pB5">Nearest Zone</td>
            <td className="w60p pB5" id="popupNearestZone">
              {": "}&nbsp;&nbsp;{data.distance.nearestzone}
            </td>
          </tr>
        )}

        {/* Data for Admins */}
        {isAdmin && (
          <tr>
            <td className="w40p pB5">{LABELS.IMEI_NO}</td>
            <td className="w60p pB5">
              {": "}&nbsp;&nbsp;{vehicle.imei}
            </td>
          </tr>
        )}
        {isAdmin && (
          <tr>
            <td className="w40p pB5">{LABELS.SIM_NUMBER}</td>
            <td className="w60p pB5">
              {": "}&nbsp;&nbsp;{vehicle.simno}
            </td>
          </tr>
        )}

        {options && (
          <tr>
            <td colSpan="2">
              <ul className="popupOptions">
                {showShare && (
                  <li>
                    <IconButton
                      className="topicon-pad"
                      onClick={this.shareLocation}
                    >
                      <Share />
                    </IconButton>
                    <span>Share</span>
                  </li>
                )}
                {showTrack && (
                  <li>
                    <IconButton
                      className="topicon-pad"
                      onClick={this.trackVehicle}
                    >
                      <DepartureBoard />
                    </IconButton>
                    <span>Track</span>
                  </li>
                )}
                <li
                  onClick={() =>
                    this.showRoute(vehicle.latitude, vehicle.longitude)
                  }
                >
                  <IconButton className="topicon-pad">
                    <Directions />
                  </IconButton>
                  <span>Show Direction</span>
                </li>
              </ul>
            </td>
          </tr>
        )}
      </tbody>
    );
  };

  getPointSummary = (vehicle, dataPoint) => {
    let params = dataPoint.params;

    var vehicleIgn = null;
    if (params.power !== undefined) {
      vehicleIgn = params.power;
    } else if (params.ignition !== undefined) {
      vehicleIgn = params.ignition;
    }

    let sensorsList = vehicle.sensors;
    for (let s = 0; s < sensorsList.length; s++) {
      sensorsList[s].value = Number(params[sensorsList[s].param]).toFixed(1);
      if (sensorsList[s].sensorType === "fuel") {
        sensorsList[s].value = commonJs.getFuelValue(sensorsList[s]);
        if (sensorsList[s].value === "N/A") {
          sensorsList[s].value = 0;
        }
        sensorsList[s].units = UNITS.FUEL;
      }
    }

    let speed = Number(dataPoint.speed).toFixed(1);
    let vstatusText = speed.toString() + " " + UNITS.SPEED;
    let vstatus = "idle";
    if (speed > 0) {
      vstatus = "running";
    }

    let address = dataPoint.latitude + "," + dataPoint.longitude;

    const { privileges, role } = this.props.loginReducer;

    const hideTemp = commonJs.hasPrivilege(
      PRIVILEGES.HIDE_TEMP,
      role,
      privileges
    );

    return (
      <tbody className={"dataPointBody"}>
        <tr>
          <td colSpan="2">
            <h4>
              <span className="popupName">{vehicle.name}</span>
              <span className={"popupStatus " + vstatus}>{vstatusText}</span>
            </h4>
            <p className="popupAddress" style={{ minWidth: 250 }}>
              {address}
            </p>
          </td>
        </tr>
        <tr>
          <td className="w40p pB5">Position</td>
          <td className="w60p pB5">
            {": "}&nbsp;&nbsp;
            <a
              href={
                "//maps.google.com/maps?q=" +
                dataPoint.latitude +
                "," +
                dataPoint.longitude
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {Number(dataPoint.latitude).toFixed(6)}°,{" "}
              {Number(dataPoint.longitude).toFixed(6)}°
            </a>
          </td>
        </tr>
        <tr>
          <td className="w40p pB5">Time</td>
          <td className="w60p pB5">
            {": "}&nbsp; {dateFunction.getFormatedDate(dataPoint.time)}
          </td>
        </tr>
        <tr>
          <td className="w40p pB5">Speed</td>
          <td className="w60p pB5">
            {": "}&nbsp; {vstatusText}
          </td>
        </tr>
        {/* <tr>
          <td className="w40p pB5">Altitude</td>
          <td className="w60p pB5">
            {": "}&nbsp; {dataPoint.altitude + " " + UNITS.Altitude}
          </td>
        </tr> */}
        <tr>
          <td className="w40p pB5">Angle</td>
          <td className="w60p pB5">
            {": "}&nbsp; {dataPoint.angle + " " + UNITS.ANGLE}
          </td>
        </tr>

        {/* Ignition */}
        {vehicleIgn !== null && (
          <tr>
            <td className="w40p pB5">Ignition</td>
            <td className="w60p pB5">
              {": "}&nbsp;&nbsp;
              {vehicleIgn ? "ON" : "OFF"}
            </td>
          </tr>
        )}

        {/* Sensors */}
        {sensorsList.map((sensor, indx) => {
          if (sensor.type === "temp" && hideTemp) {
            return null;
          }
          return (
            <tr key={"sensortype-" + indx}>
              <td className="w40p pB5">{sensor.name}:</td>
              <td className="w60p pB5">
                {": "}&nbsp;&nbsp;
                {sensor.value}
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  render() {
    const { vehicle, data, datapoint, options } = this.props;
    return (
      <Popup>
        <div className="markerPopup">
          <table>
            {datapoint
              ? this.getPointSummary(vehicle, data)
              : this.getMarkerSummary(vehicle, data, options)}
          </table>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPopup);
