import React, { Component } from "react";
import { connect } from "react-redux";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Visibility,
} from "@material-ui/icons";
import { Divider } from "@material-ui/core";

import CheckBoxGroup from "components/ui/checkbox/CheckBoxGroup";
import FleetPanel from "components/ui/panel/FleetPanel";
import SearchButton from "components/ui/searchbutton/SearchButton";
import Btn from "components/ui/button/Btn";

import { DEFAULTS, LABELS } from "common/consts";
import { MAP_ACTIONS } from "actions/MapActions";
import { API_Settings } from "apihandler/Settings";
import { PAGE_ACTIONS } from "actions/PageActions";

import "../maptab.css";

class FilterGroup extends Component {
  constructor(props) {
    super(props);
    const { signalFilter, loadStatusFilter } = this.props.mapReducer;
    this.state = {
      expandSignalStatus: true,
      expandVehicleStatus: false,
      expandGroups: false,
      expandMainGroups: false,
      expandCities: false,
      expandStates: false,
      mainGroups: [],
      filterMainGroups: [],
      tmpSignalFilter: signalFilter,
      tmpLoadStatusFilter: loadStatusFilter,
    };
    this._timer = null;
  }

  componentDidMount() {
    this.getMainGroups();
    this.getAllUserGroups();
  }

  getMainGroups = () => {
    const { uid } = this.props.loginReducer;
    const self = this;
    API_Settings.getMainGroups({ userId: uid }).then((res) => {
      if (res.Result === 200) {
        const mainGroups = [
          { id: -1, name: "All", groups: [], checked: true },
          { id: 0, name: "Ungrouped", groups: [], checked: true },
        ];
        const list = res.Response;
        for (let g in list) {
          list[g].count = 0;
          list[g].id = Number(list[g].id);
          list[g].checked = true;
          list[g].indeterminate = false;
          list[g].groups = list[g].groups.map((g) => {
            g.checked = true;
            g.indeterminate = false;
            return g;
          });
          mainGroups.push(list[g]);
        }
        self.setState({
          mainGroups,
        });
      }
    });
  };

  getAllUserGroups = () => {
    let args = {
      pageNo: 1,
      pageLength: 1000,
    };
    const self = this;
    API_Settings.getAllUserGroups(args).then((res) => {
      if (res.Result === 200) {
        const { Response } = res;
        let list = [
          {
            id: 0,
            name: LABELS.UNGROUPED,
            checked: true,
          },
        ];
        for (let g = 0; g < Response.length; g++) {
          list.push({
            id: Number(Response[g].id),
            name: Response[g].name,
            checked: true,
          });
        }
        const objList = { 0: { name: LABELS.UNGROUPED, vcount: 0 } };
        for (let i = 0; i < list.length; i++) {
          if (!objList[list[i].id]) {
            objList[list[i].id] = {
              name: list[i].name,
              vcount: 0,
            };
          }
        }
        self.props.setUserGroups({
          userGroups: objList,
        });
      }
    });
  };

  loadExpandIcon = () => {
    return <AddCircleOutline />;
  };

  loadLeftIcon = () => {
    return <Visibility />;
  };

  loadMinimizeIcon = () => {
    return <RemoveCircleOutline />;
  };

  onSearch = () => {};

  onFilterSignalStatus = (obj) => {
    this.setState({
      tmpSignalFilter: obj.id,
    });
  };

  onFilterVehicleStatus = (obj) => {
    this.setState({
      tmpLoadStatusFilter: obj.id,
    });
  };

  onFilterGroup = (selectedMainGroup = {}, selectedGroup = {}, type = "") => {
    const { mainGroups } = this.state;
    let listMainGroups = [];
    if (type === "main") {
      // Toggle Main Groups
      const mainGroupchkStatus = !selectedMainGroup.checked;
      // If "All" clicked
      if (selectedMainGroup.id === -1) {
        // Select or Deselect All
        listMainGroups = mainGroups.map((l) => {
          l.checked = mainGroupchkStatus;
          l.indeterminate = false;
          l.groups = l.groups.map((g) => {
            g.checked = mainGroupchkStatus;
            return g;
          });
          return l;
        });
      } else {
        // Select or Deselect Particular "Main Group"
        let chkMainTotal = 0;
        listMainGroups = mainGroups.map((l) => {
          if (l.id === selectedMainGroup.id) {
            l.checked = mainGroupchkStatus;
            if (mainGroupchkStatus) {
              l.indeterminate = false;
            }
            l.groups = l.groups.map((g) => {
              g.checked = mainGroupchkStatus;
              if (mainGroupchkStatus) {
                g.indeterminate = false;
              }
              return g;
            });
          }
          if (l.checked && l.id > -1) {
            chkMainTotal++;
          }
          return l;
        });
        if (chkMainTotal === 0) {
          listMainGroups[0].checked = false;
          listMainGroups[0].indeterminate = false;
        } else if (chkMainTotal === mainGroups.length - 1) {
          listMainGroups[0].checked = true;
          listMainGroups[0].indeterminate = false;
        } else {
          listMainGroups[0].checked = true;
          listMainGroups[0].indeterminate = true;
        }
      }
    } else {
      // Toggle Sub Groups
      const groupChkStatus = !selectedGroup.checked;
      let totalChkdSubGroups = 0;
      listMainGroups = mainGroups.map((l) => {
        if (l.groups.length > 0) {
          // Same Main & Sub Group => Toggle
          if (l.id === selectedMainGroup.id) {
            l.groups = l.groups.map((g) => {
              if (g.id === selectedGroup.id) {
                g.checked = groupChkStatus;
              }
              return g;
            });
          }
          // Actual Checked Sub Groups = 0 => Main Group => "uncheck" : "indeterminate"
          let actualChk = l.groups.filter((f) => f.checked === true);
          if (actualChk.length === 0) {
            l.checked = false;
            l.indeterminate = false;
          } else {
            if (actualChk.length === l.groups.length) {
              l.indeterminate = false;
              l.checked = true;
            } else {
              l.checked = false;
              l.indeterminate = true;
            }
          }
          totalChkdSubGroups += actualChk.length;
        } else {
          // For Ungrouped
          if (l.checked & (l.id === 0)) {
            totalChkdSubGroups += 1;
          }
        }
        return l;
      });
      // If all sub groups are unchecked => unselected "All"
      if (totalChkdSubGroups === 0) {
        listMainGroups[0].checked = false;
        listMainGroups[0].indeterminate = false;
      } else {
        // Any one/all main group should be selected
        const mlist = listMainGroups.filter((l) => l.checked && l.id > -1);
        if (mlist.length === mainGroups.length - 1) {
          // All
          listMainGroups[0].checked = true;
          listMainGroups[0].indeterminate = false;
        } else {
          listMainGroups[0].checked = true;
          listMainGroups[0].indeterminate = true;
        }
      }
    }
    this.setState({
      mainGroups: listMainGroups,
    });
  };

  onFilter = () => {
    this.props.setLoading({
      loadingPopover: true,
    });
    const { tmpSignalFilter, tmpLoadStatusFilter, mainGroups } = this.state;
    const { tmpVehicles } = this.props.mapReducer;
    // Get Filtered Group Ids
    let filteredGroups = [];
    if (mainGroups[0].checked === true && !mainGroups[0].indeterminate) {
      filteredGroups.push(-1);
    } else if (mainGroups[1].checked === true) {
      filteredGroups.push(0);
    }
    if (mainGroups[0].indeterminate) {
      mainGroups.map((m) => {
        m.groups.map((g) => {
          if (g.checked) {
            if (filteredGroups.indexOf(g.id) === -1) {
              filteredGroups.push(g.id);
            }
          }
          return g;
        });
        return m;
      });
    }
    // Filter Vehicles as per Group Ids
    let fList = [];
    if (filteredGroups.length > 0) {
      fList = tmpVehicles.filter((v) => {
        if (filteredGroups[0] === -1) {
          return true;
        }
        return filteredGroups.indexOf(v.groupId) > -1;
      });
    }
    if (fList.length === 0) {
      fList.push(0);
    }
    this.props.setFilters({
      signalFilter: tmpSignalFilter,
      loadStatusFilter: tmpLoadStatusFilter,
      filteredVehicles: fList,
    });
    document.getElementById("vehicleSearch").value = "";
    this.props.setLoading({
      loadingPopover: false,
    });
  };

  onResetFilter = () => {
    this.props.setFilters({
      signalFilter: DEFAULTS.DEFAULT_VEHICLE_STATUS,
      loadStatusFilter: DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS,
      filteredVehicles: [],
    });
    const mainGroups = this.state.mainGroups.map((m) => {
      m.checked = true;
      m.indeterminate = false;
      m.groups.map((g) => {
        g.checked = true;
        g.indeterminate = false;
        return g;
      });
      return m;
    });
    this.setState({
      mainGroups,
      tmpSignalFilter: DEFAULTS.DEFAULT_VEHICLE_STATUS,
      tmpLoadStatusFilter: DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS,
    });
  };

  render() {
    const {
      expandGroups,
      expandMainGroups,
      expandSignalStatus,
      expandVehicleStatus,
      mainGroups,
      filterMainGroups,
      tmpSignalFilter,
      tmpLoadStatusFilter,
    } = this.state;

    const { filteredVehicles } = this.props.mapReducer;

    const { vehiclesList } = this.props.commonReducer;

    const signalStatusList = DEFAULTS.DEFAULT_VEHICLE_STATUS_LIST.map(
      (s, index) => {
        // Find the Count of vehicles as per signal status
        let vCount = vehiclesList.length;
        if (filteredVehicles.length > 0) {
          vCount = filteredVehicles.length;
          if (filteredVehicles[0] === 0) {
            vCount = 0;
          }
        }
        if (index > 0) {
          // For All "filter" not needed
          let vList = [];
          if (filteredVehicles.length > 0) {
            vList = filteredVehicles;
          } else {
            vList = vehiclesList;
          }
          let fList = vList.filter((v) => {
            return v.status === index - 1;
          });
          vCount = fList.length;
        }
        return {
          id: index,
          name: s + " (" + vCount + ")",
          checked: tmpSignalFilter === index,
        };
      }
    );

    const vehicleStatusList = DEFAULTS.DEFAULT_VEHICLE_LOAD_STATUS_LIST.map(
      (v, index) => {
        let vCount = vehiclesList.length;
        if (index > 0) {
          let fList = vehiclesList.filter((t) => {
            return t.loadstatus === index - 1;
          });
          vCount = fList.length;
        }
        return {
          id: index,
          name: v + " (" + vCount + ")",
          checked: tmpLoadStatusFilter === index,
        };
      }
    );

    const { userGroups } = this.props.mapReducer;
    let groupsList =
      filterMainGroups.length > 0 ? filterMainGroups : mainGroups;
    for (let i = 0; i < groupsList.length; i++) {
      const { id, groups } = groupsList[i];
      if (id === -1) {
        let vcount = 0;
        for (let gId in userGroups) {
          if (!userGroups[gId].vcount) {
            userGroups[gId].vcount = 0;
          }
          vcount += userGroups[gId].vcount;
        }
        groupsList[i].count = vcount;
      } else if (id === 0) {
        let vcount = userGroups[id] ? userGroups[id].vcount : 0;
        groupsList[i].count = vcount;
      } else {
        let count = 0;
        for (let i = 0; i < groups.length; i++) {
          let vcount = userGroups[groups[i].id].vcount;
          groups[i].count = vcount;
          count += vcount;
        }
        groupsList[i].count = count;
      }
    }

    return (
      <React.Fragment>
        <div id="sStatusFilter" className="filterBox">
          <FleetPanel
            loadExpandIcon={this.loadExpandIcon()}
            loadMinimizeIcon={this.loadMinimizeIcon()}
            defaultExpand={expandSignalStatus}
            title={LABELS.SIGNAL_STATUS}
          >
            <CheckBoxGroup
              name="grouplist"
              list={signalStatusList}
              handleFilterChange={this.onFilterSignalStatus}
            />
          </FleetPanel>
        </div>

        <Divider className="mB10" />

        <div id="vStatusFilter" className="filterBox">
          <FleetPanel
            loadExpandIcon={this.loadExpandIcon()}
            loadMinimizeIcon={this.loadMinimizeIcon()}
            defaultExpand={expandVehicleStatus}
            title={LABELS.VEHICLE_STATUS}
          >
            <CheckBoxGroup
              name="grouplist"
              list={vehicleStatusList}
              handleFilterChange={this.onFilterVehicleStatus}
            />
          </FleetPanel>
        </div>

        <Divider className="mB10" />

        <div id="mainGroupFilter" className="filterBox">
          <FleetPanel
            loadExpandIcon={this.loadExpandIcon()}
            loadMinimizeIcon={this.loadMinimizeIcon()}
            defaultExpand={expandMainGroups}
            title="Groups"
          >
            {/* <SearchButton
              id="mainGroupSearch"
              placeholder="Search By Name"
              buttonClass="pad5 iconButton searchBtn"
              inputClass="SearchInput"
              iconwidth="18"
              iconheight="18"
              onChange={this.onSearch}
            /> */}
            <CheckBoxGroup
              name="mainGrouplist"
              list={groupsList}
              treeView={true}
              handleFilterChange={this.onFilterGroup}
            />
          </FleetPanel>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Btn onClick={this.onFilter} className={"settingsBtn saveBtn"}>
            {"Filter"}
          </Btn>
          <Btn onClick={this.onResetFilter} className={"closeBtn"}>
            {"Reset"}
          </Btn>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginReducer: state.loginReducer,
    mapReducer: state.mapReducer,
    commonReducer: state.commonReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserGroups: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_GROUPS,
        values,
      });
    },
    setVehicles: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_FILTERED_VEHICLES,
        values,
      });
    },
    setSignalFilterStatus: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_SIGNALFILTER_STATUS,
        values,
      });
    },
    setVehicleStatusFilter: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_LOADFILTER_STATUS,
        values,
      });
    },
    setFilters: (values) => {
      dispatch({
        type: MAP_ACTIONS.SET_FILTERS,
        values,
      });
    },
    setLoading: (values) => {
      dispatch({
        type: PAGE_ACTIONS.SET_POPOVER_LOADING,
        values,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterGroup);
